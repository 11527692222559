import { FC } from "react";
import { Skeleton } from "src/atoms/Skeleton";
import { ProductPhoto } from "src/molecules/ProductPhoto";
import {
  StyledProductCard,
  StyledCardBrand,
  StyledCardProduct,
  StyledCardPrices,
  StyledCardSizes,
} from "src/molecules/ProductCard/ProductCard.styled";
import { Box } from "@otrium/core";
interface ProductCardFallbackProps {
  className?: string;
  rest?: any;
}

const ProductCardFallback: FC<ProductCardFallbackProps> = ({
  className,
  ...rest
}) => {
  return (
    <StyledProductCard className={className} {...rest}>
      <Box
        display="block"
        sx={{
          position: "relative",
        }}
      >
        <ProductPhoto>
          <Skeleton />
        </ProductPhoto>
      </Box>
      <StyledCardBrand isPlaceholder>
        <Skeleton />
      </StyledCardBrand>
      <StyledCardProduct isPlaceholder>
        <Skeleton />
      </StyledCardProduct>
      <StyledCardPrices isPlaceholder>
        <Skeleton />
      </StyledCardPrices>
      <StyledCardSizes isPlaceholder>
        <Skeleton />
      </StyledCardSizes>
    </StyledProductCard>
  );
};

export default ProductCardFallback;
