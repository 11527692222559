import styled from "@emotion/styled";
import { FontSizeKey, SpacingKey } from "src/types/emotion";

export type CustomFontSizes = {
  numbers?: FontSizeKey;
  divider?: FontSizeKey;
  label?: FontSizeKey;
};

export type CustomSpacings = {
  numbersGap?: SpacingKey;
  containerMarginBottom?: SpacingKey;
};

const TimerContainer = styled.div<{
  customFontSizes?: CustomFontSizes;
  customSpacings?: CustomSpacings;
  timerColor?: string;
}>`
  display: grid;
  width: fit-content;
  gap: ${({ theme, customSpacings }) =>
    theme.space[customSpacings?.numbersGap || "space8"]};
  grid-template-areas: "timer-block-1 divider-1 timer-block-2 divider-2 timer-block-3 divider-3 timer-block-4";
  margin-bottom: ${({ theme, customSpacings }) =>
    theme.space[customSpacings?.containerMarginBottom || "_12"]};
  color: ${({ timerColor }) => timerColor || "inherit"};
  font-weight: inherit;


  .timer-block {
    display: grid;
    align-items: center;
    justify-content: center;
    gap: 1px;
    max-height: max-content;
    grid-template-areas:
      "timer-block"
      "label";
    grid-template-rows: 1fr minmax(
        0,
        ${({ theme, customFontSizes }) =>
          theme.fontSizes[customFontSizes?.label || "fontSize12"]}
      );
    place-items: center;

    &.days {
      grid-area: timer-block-1;
    }

    &.hrs {
      grid-area: timer-block-2;
    }

    &.mins {
      grid-area: timer-block-3;
    }

    &.sec {
      grid-area: timer-block-4;
    }

    &--numbers {
      font-size: ${({ theme, customFontSizes }) =>
        customFontSizes?.numbers
          ? theme.fontSizes[customFontSizes.numbers]
          : "inherit"}
      grid-area: timer-block;
      flex-shrink: 0;
    }

    &--label {
      grid-area: label;
      font-size: ${({ theme, customFontSizes }) =>
        theme.fontSizes[customFontSizes?.label || "fontSize12"]};
      font-weight: 300;
      height: fit-content;
      text-transform: uppercase;
      margin-top: -12px;

      @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        margin-top: -20px;
      }
    }

    &--no-labels {
      grid-template-rows: 1fr;
    }
  }

  .timer-divider {
    font-size: ${({ theme, customFontSizes }) =>
      theme.fontSizes[customFontSizes?.divider || "fontSize32"]};

    &.days {
      grid-area: divider-1;
    }

    &.hrs {
      grid-area: divider-2;
    }

    &.mins {
      grid-area: divider-3;
    }
  }

  &.promotimer {
    gap: 0;
    margin-bottom: 0;

    .timer-block {
      &--numbers {
        font-size: ${({ theme, customFontSizes }) =>
          theme.fontSizes[customFontSizes?.numbers || "fontSize32"]};
        font-weight: 700;
      }

      &--label {
        margin-top: -10px;
      }
    }

    .timer-divider {
      margin-top: -2px;
      font-weight: 700;
    }
  }

  &.bannertimer {
    margin-bottom: 0;
    gap: 2px;

    .timer-block {
      grid-template-areas: "timer-block label";
      grid-template-rows: 1fr;

      &--number {
        font-size: ${({ theme }) => theme.fontSizes?.fontSize16};
        color: ${({ theme }) => theme.colors.semantic.red};
      }

      &--label {
        margin-top: 0;
        text-transform: lowercase;
        font-size: ${({ theme }) => theme.fontSizes?.fontSize16};
      }
    }

    .timer-divider {
      font-size: ${({ theme }) => theme.fontSizes?.fontSize16};
    }
  }
    
`;

export { TimerContainer };
