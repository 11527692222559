import { useState, useEffect, useRef } from "react";

export type TimeLeftKeys = "days" | "hrs" | "mins" | "sec";

type TimeLeft = Record<TimeLeftKeys, string>;

const calculateTimeLeft = (endTime: string): TimeLeft => {
  const difference = +new Date(endTime) - +new Date();
  let timeLeft: TimeLeft = {
    days: "00",
    hrs: "00",
    mins: "00",
    sec: "00",
  };

  if (difference > 0) {
    timeLeft = {
      days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(
        2,
        "0"
      ),
      hrs: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(
        2,
        "0"
      ),
      mins: String(Math.floor((difference / 1000 / 60) % 60)).padStart(2, "0"),
      sec: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
    };
  }

  return timeLeft;
};

const useTimer = ({
  endTime,
  onTimerEnd,
}: {
  endTime: string;
  onTimerEnd?: (hasTimerEnded: boolean) => void;
}): { timeLeft: TimeLeft; hasTimerEnded: boolean } => {
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [timeLeft, setTimeLeft] = useState<TimeLeft>(
    calculateTimeLeft(endTime)
  );
  const hasTimerEnded = Object.values(timeLeft).every(
    (value) => Number(value) === 0 || !endTime
  );

  useEffect(() => {
    if (hasTimerEnded) {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }

      if (onTimerEnd) {
        onTimerEnd(hasTimerEnded);
      }

      return;
    }

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft(endTime));
    }, 1000);

    timerRef.current = timer;

    return () => clearInterval(timer);
  }, [endTime, hasTimerEnded, onTimerEnd]);

  return { timeLeft, hasTimerEnded };
};

export default useTimer;
