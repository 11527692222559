import {
  Dispatch,
  FC,
  JSX,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useState,
} from "react";
import { useRouter } from "next/router";
import { Heading, Text } from "@otrium/atoms";
import { Box, Flex, useBreakpoints } from "@otrium/core";
import { Trans } from "@lingui/macro";
import { getI18nRoute } from "src/utils/i18nRoutes";
import { Locale } from "lib/Intl";
import Link from "next/link";
import { stringifyUrl } from "query-string";
import {
  BrandGateLevelInCustomerPlatform,
  BrandLabel,
  HomePageSingleBrand,
  HomePageSingleBrandLabel,
  ImageBannerData,
  PromotionDisplayType,
  SummaryBrand,
  WishlistItemType,
} from "src/types/graphql.d";
import { Ratio } from "src/atoms/Ratio";
import { Skeleton } from "src/atoms/Skeleton";
import { Label } from "src/atoms/Label";
import {
  StyledBrandCard,
  StyledBrandCardImage,
  StyledBrandCardImageWrapper,
  StyledBrandCardInfo,
  StyledBrandCardInfoLabelWrapper,
  StyledBrandCardInfoText,
  StyledBrandCardLineClamp,
  StyledBrandCardLogo,
} from "./BrandCard.styled";
import { ArrowNext } from "src/icons/ArrowNext";
import { ToggleWishListButton } from "src/molecules/ToggleWishListButton";
import { useDeleteWishlistItem } from "src/hooks/deleteWishlistItem";
import { useAddToWishList } from "src/hooks/useAddToWishlist";
import { useImageError } from "src/hooks/useImageError";
import { formatName } from "src/utils/formatName";
import { localeFix } from "src/utils/genderLocale/genderLocale";
import { useSegment } from "src/hooks/useSegment";
import { useToggleWishlistBrandItem } from "src/hooks/toggleWishlistItem";
import { useIsLoggedIn } from "src/hooks/useIsLoggedIn";
import {
  SEGMENT_PROMOTION_TYPE,
  SEGMENT_LIST_FORMAT,
  SEGMENT_LIST_TYPE,
} from "src/segment";
import NoSSR from "src/atoms/NoSSR";
import { Timer } from "src/atoms/Timer";
import { LockIcon, LockUnlockIcon } from "@otrium/icons";
import { LoginRegisterModal } from "src/molecules/LoginRegisterModal";
import { GATED_POPUP_REDIRECT_DATA } from "src/constants/gatedItem";
import {
  removeStorageItem,
  setStorageJSON,
} from "src/utils/helper/localStorage";
import { theme } from "src/theme";
import { useFeatureFlags } from "src/hooks/useFeatureFlags";

interface BrandCardLabelsProps {
  labels: BrandLabel[] | HomePageSingleBrandLabel[];
}

const BrandCardLabels: FC<BrandCardLabelsProps> = ({ labels }): JSX.Element => {
  const firstTwoLabels = labels?.slice(0, 2);
  return (
    <div>
      {(firstTwoLabels as Array<BrandLabel | HomePageSingleBrandLabel>).map(
        (label: BrandLabel | HomePageSingleBrandLabel, index: number) => (
          <StyledBrandCardInfoLabelWrapper
            key={`StyledBrandCardInfoLabelWrapper-${index}`}
          >
            <Label
              type={label.type}
              value={label.value}
              color={label.color}
              textColor={label.text_color}
              showBackground={false}
            />
          </StyledBrandCardInfoLabelWrapper>
        )
      )}
    </div>
  );
};

interface Props {
  associatedCategoryId?: string | null;
  brand: SummaryBrand | HomePageSingleBrand | ImageBannerData;
  genderId: string;
  getImageElementWrapper?: Dispatch<SetStateAction<HTMLDivElement | null>>;
  locale: Locale;
  minimal?: boolean;
  onClickHandler?: () => void;
  orderByNew?: boolean;
  refetchQueriesOnDeleteWishListBrand?: string[];
  disableTranslation?: boolean;
  brandType?: string;
  position?: string;
  tab?: string;
  handleTimerExpired?: (link: string) => void;
}

const BrandCard: FC<PropsWithChildren<Props>> = ({
  brand,
  genderId,
  locale,
  orderByNew = false,
  minimal = false,
  refetchQueriesOnDeleteWishListBrand = [],
  associatedCategoryId,
  getImageElementWrapper,
  onClickHandler,
  disableTranslation = false,
  brandType,
  position,
  tab,
  handleTimerExpired,
  ...props
}) => {
  const {
    id: brandId,
    image,
    overlay_logo,
    logo_svg,
    subtitle,
    slug,
    labels,
    discount,
    name: brandName,
  } = brand as SummaryBrand | HomePageSingleBrand;

  const { gate } = brand as HomePageSingleBrand;

  const {
    block_uuid,
    link,
    title,
    title_color,
    subtitle_color,
    image_mobile,
    image_desktop,
    display_type,
    end_date,
  } = brand as ImageBannerData;
  const { asPath } = useRouter();
  const [setLogoImg, hasLogoImgError] = useImageError();
  const [setBackgroundImg, hasBackgroundImgError] = useImageError();
  const [hovered, setHovered] = useState(false);
  const [open, setOpen] = useState<boolean>(false);
  const isLoggedIn = useIsLoggedIn();
  const { isMobile } = useBreakpoints();
  const [hasSaleExpired, setSaleExpired] = useState(false);

  const { enableGatedHomepage } = useFeatureFlags();
  const isGated =
    enableGatedHomepage &&
    !isLoggedIn &&
    gate &&
    gate.level !== BrandGateLevelInCustomerPlatform.NonGated;

  const [setBackgroundMinimalImg, hasBackgroundMinimalImgError] =
    useImageError();

  const { brandFavourited, brandUnfavourited, brandFavouritedWhenLoggedOut } =
    useSegment();

  const { addedToWishlist } = useToggleWishlistBrandItem(brandId);

  const { deleteWishListItem, loading: deleteLoading } = useDeleteWishlistItem({
    refetchQueries: refetchQueriesOnDeleteWishListBrand,
  });

  const { addToWishlist, loading: addLoading } = useAddToWishList({
    refetchQueries: ["wishlistBrands", "userWishlistMetaData"],
  });

  const brandWishlistTracking = {
    brandName,
    brandId,
  };

  const hasSubtitleOrDiscount = !!(subtitle || discount);

  const onAddToWishList = useCallback(
    async (itemType: WishlistItemType, itemId: string) => {
      if (isLoggedIn) {
        await addToWishlist(itemType, itemId);
        void brandFavourited(brandWishlistTracking);
        return;
      } else if (enableGatedHomepage) {
        setOpen(true);
      }
      void brandFavouritedWhenLoggedOut(brandWishlistTracking);
    },
    [addToWishlist, isLoggedIn] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onRemoveFromWishList = useCallback(
    async (itemType: WishlistItemType, itemId: string) => {
      await deleteWishListItem(itemType, itemId, {});
      void brandUnfavourited(brandWishlistTracking);
    },
    [deleteWishListItem] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const onTimerExpire = useCallback(
    (hasTimerEnded: boolean) => {
      if (!hasTimerEnded || !handleTimerExpired || !block_uuid) {
        return;
      }

      handleTimerExpired(block_uuid);
    },
    [handleTimerExpired, block_uuid]
  );

  const orderBy = orderByNew ? "new" : undefined;

  const singleBrand = brand as SummaryBrand | HomePageSingleBrand;

  const brandGenders = singleBrand?.genders;

  const hasSale =
    (display_type &&
      (display_type as PromotionDisplayType) !==
        PromotionDisplayType.Default) ||
    false;

  const areGendersAvailable =
    Array.isArray(brandGenders) && brandGenders.length > 0;

  const getGender =
    areGendersAvailable && !brandGenders?.includes(genderId)
      ? brandGenders?.[0]
      : genderId;

  const routePath = `${getI18nRoute({
    route: `/brands`,
    locale,
  })}/${slug}${getI18nRoute({
    route: `/${localeFix({ gender: getGender })}`,
    locale,
  })}`;

  const path =
    link ||
    stringifyUrl({
      url: routePath,
      query: { orderBy, cato_cat: associatedCategoryId || undefined },
    });

  let cardRatio;

  if (brandType === SEGMENT_PROMOTION_TYPE.imageBanners) {
    cardRatio = isMobile ? 0.67 : 0.65;
  }

  const handleContextMenu = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      setOpen(true);
    },
    [setOpen]
  );

  const handleMouseEnter = useCallback(() => {
    if (isMobile) {
      return;
    }

    setHovered(true);
  }, [isMobile]);

  const handleMouseLeave = useCallback(() => {
    if (isMobile) {
      return;
    }

    setHovered(false);
  }, [isMobile]);

  const setRedirectData = (type: string) => {
    removeStorageItem(GATED_POPUP_REDIRECT_DATA);
    setStorageJSON(GATED_POPUP_REDIRECT_DATA, {
      gatedActionType: type,
      promo_id: brandId,
      promo_name: `${brandName}-${discount || ""}`,
      promo_creative: image,
      promo_position: `homepage-featured-shop-${tab || ""}_${position || ""}`,
      promo_type: SEGMENT_PROMOTION_TYPE.brandCarousel as string,
      promo_path: asPath,
      productListMeta: {
        listFormat: SEGMENT_LIST_FORMAT.carousel,
        listId: slug,
        listType: SEGMENT_LIST_TYPE.brand,
        shopType: genderId,
      },
      tab,
      actionSource: SEGMENT_PROMOTION_TYPE.brandCarousel as string,
    });
  };

  // render the minimal version
  if (minimal) {
    return (
      <Link passHref href={path} legacyBehavior>
        <StyledBrandCard
          {...props}
          hasSaleExpired={hasSale && hasSaleExpired}
          aria-disabled={hasSale && hasSaleExpired}
          tabIndex={hasSale && hasSaleExpired ? -1 : undefined}
        >
          {brandId && (
            <ToggleWishListButton
              itemType={WishlistItemType.Brand}
              itemId={brandId}
              loading={deleteLoading || addLoading}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onAddToWishList={onAddToWishList}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onRemoveWishList={onRemoveFromWishList}
              isAddedToWishList={addedToWishlist}
            />
          )}
          <Ratio ratio={1.32}>
            <StyledBrandCardImageWrapper isBottomRadius={true}>
              {!hasBackgroundMinimalImgError &&
                (image || image_desktop || image_mobile) && (
                  <StyledBrandCardImage
                    ref={setBackgroundMinimalImg}
                    imageDesktop={image || image_desktop}
                    imageMobile={image || image_mobile}
                    isBottomRadius={true}
                  />
                )}
            </StyledBrandCardImageWrapper>
          </Ratio>
          <Box
            pb={3}
            width={1}
            sx={{
              "&::after": {
                backgroundImage:
                  "linear-gradient(to bottom,rgba(0,0,0,0),#000000)",
                content: "''",
                height: "150%",
                width: "100%",
                position: "absolute",
                bottom: 0,
                opacity: 0.7,
                zIndex: 0,
              },
              position: "absolute",
              bottom: 0,
            }}
          >
            <Box sx={{ position: "relative", zIndex: 1 }}>
              {brandName && (
                <Text
                  color="tone.white"
                  textAlign={"center"}
                  fontSize={2}
                  pb={0}
                  sx={{
                    fontWeight: 800,
                    textTransform: "uppercase",
                    letterSpacing: "1.3px",
                  }}
                >
                  {formatName(brandName)}
                </Text>
              )}
              {brand.subtitle && (
                <Text
                  fontSize={1}
                  color={subtitle_color || "tone.white"}
                  textAlign={"center"}
                >
                  {brand.subtitle}
                </Text>
              )}
            </Box>
          </Box>
        </StyledBrandCard>
      </Link>
    );
  }

  const renderLogoOrText = () =>
    !hasLogoImgError && (logo_svg || overlay_logo) ? (
      <StyledBrandCardLogo
        ref={setLogoImg}
        src={logo_svg || (overlay_logo as string)}
        alt={brandName}
      />
    ) : (
      <Flex
        fontSize={3}
        sx={{
          position: "absolute",
          zIndex: "11",
          top: "75%",
          left: "50%",
          color: "white",
          fontWeight: "bold",
          bottom: 0,
          textTransform: "uppercase",
          textShadow: "1px #000",
          letterSpacing: "1.3px",
          textAlign: "center",
          transform: "translate(-50%, -50%)",
          width: "100%",
        }}
        alignItems="center"
      >
        <Text>{brandName}</Text>
      </Flex>
    );

  const renderImage = () =>
    !hasBackgroundImgError &&
    (image || image_desktop || image_mobile) && (
      <>
        {title && (
          <Heading
            variant="h2"
            color={title_color}
            sx={{
              position: "absolute",
              top: "75%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              textAlign: "center",
              zIndex: 10,
              width: "100%",
            }}
          >
            <StyledBrandCardLineClamp>{title}</StyledBrandCardLineClamp>
          </Heading>
        )}
        <StyledBrandCardImage
          ref={setBackgroundImg}
          imageDesktop={image || image_desktop}
          imageMobile={image || image_mobile}
          isBottomRadius={!hasSubtitleOrDiscount}
          hovered={hovered}
          isGated={isGated || false}
          isMobile={isMobile}
        />
      </>
    );

  const renderFooter = () => (
    <Flex
      alignItems="center"
      bg="tone.white"
      width={1}
      px={"space16"}
      sx={{
        justifyContent: "space-between",
        flexFlow: "row wrap",
        position: "relative",
        zIndex: 11,
        border: "1px solid #eeeeee",
        borderTop: "none",
        flex: "1 1 auto",
        height: 64,
        borderRadius: "0 0 8px 8px",
      }}
    >
      <StyledBrandCardInfoText>
        <Flex flexDirection="column" flex="1 0">
          <Flex alignItems="center">
            {hovered ? (
              <LockUnlockIcon
                width={20}
                height={20}
                color={theme.colors.tone.black}
                style={{ margin: "0 6px" }}
              />
            ) : (
              <LockIcon
                color={theme.colors.tone.black}
                width={32}
                height={32}
                style={{
                  background: theme.colors.primary.goldLight,
                  borderRadius: "16px",
                  padding: "6px",
                }}
              />
            )}
            <Text
              fontSize={"fontSize16"}
              sx={{
                letterSpacing: "0.4px",
                lineHeight: "24px",
                fontWeight: "600",
                paddingLeft: "8px",
                color: subtitle_color || "tone.black",
                ":first-letter": {
                  textTransform: subtitle ? "none" : "capitalize",
                },
              }}
            >
              <StyledBrandCardLineClamp>
                <Trans>Log in for full access</Trans>
              </StyledBrandCardLineClamp>
            </Text>
          </Flex>
        </Flex>
        <NoSSR>
          <Flex
            sx={{
              marginLeft: "auto",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ArrowNext role="presentation" />
          </Flex>
        </NoSSR>
      </StyledBrandCardInfoText>
    </Flex>
  );

  return (
    <>
      {isGated ? (
        <StyledBrandCard
          {...props}
          onClick={() => setOpen(true)}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onContextMenu={handleContextMenu}
        >
          <Box
            sx={{
              position: "absolute",
              right: "16px",
              top: "16px",
              zIndex: 1,
            }}
          >
            <LockIcon width={20} height={20} color={theme.colors.tone.white} />
          </Box>
          <Ratio ratio={cardRatio || 0.72}>
            {renderLogoOrText()}
            <StyledBrandCardImageWrapper
              ref={getImageElementWrapper}
              isBottomRadius={!hasSubtitleOrDiscount}
              noGradient={brandType === "image_banners"}
            >
              {renderImage()}
            </StyledBrandCardImageWrapper>
          </Ratio>
          {renderFooter()}
        </StyledBrandCard>
      ) : (
        <Link passHref href={path} legacyBehavior>
          <StyledBrandCard
            {...props}
            onClick={onClickHandler}
            hasSaleExpired={hasSale && hasSaleExpired}
          >
            {brandId && (
              <ToggleWishListButton
                itemType={WishlistItemType.Brand}
                itemId={brandId}
                loading={deleteLoading || addLoading}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onAddToWishList={onAddToWishList}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onRemoveWishList={onRemoveFromWishList}
                isAddedToWishList={addedToWishlist}
              />
            )}
            <Ratio ratio={cardRatio || 0.72}>
              {renderLogoOrText()}
              <StyledBrandCardImageWrapper
                ref={getImageElementWrapper}
                isBottomRadius={!hasSubtitleOrDiscount}
                noGradient={brandType === "image_banners"}
              >
                {renderImage()}
              </StyledBrandCardImageWrapper>
            </Ratio>
            {hasSubtitleOrDiscount && (
              <Flex
                alignItems="center"
                bg="tone.white"
                width={1}
                px={"space16"}
                sx={{
                  justifyContent: "space-between",
                  flexFlow: "row wrap",
                  position: "relative",
                  zIndex: 11,
                  border: "1px solid #eeeeee",
                  borderTop: "none",
                  flex: "1 1 auto",
                  height: 64,
                  borderRadius: "0 0 8px 8px",
                }}
              >
                <StyledBrandCardInfoText>
                  <Flex flexDirection="column" flex="1 0">
                    {labels && (
                      <Box sx={{ whiteSpace: "nowrap" }}>
                        <BrandCardLabels labels={labels} />
                      </Box>
                    )}
                    <Text
                      fontSize={"fontSize16"}
                      as={hasSale ? "div" : "p"}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                        letterSpacing: "0.4px",
                        lineHeight: "24px",
                        fontWeight: "600",
                        color: subtitle_color || "tone.black",
                        ":first-letter": {
                          textTransform: subtitle ? "none" : "capitalize",
                        },
                      }}
                    >
                      {(subtitle || discount) && (
                        <>
                          <StyledBrandCardLineClamp
                            style={{
                              marginRight: hasSale ? theme.space.space8 : 0,
                            }}
                          >
                            {hasSale && hasSaleExpired ? (
                              <>
                                {(display_type as PromotionDisplayType) ===
                                PromotionDisplayType.FlashSale ? (
                                  <Trans>Flash sale has ended</Trans>
                                ) : null}
                                {(display_type as PromotionDisplayType) ===
                                PromotionDisplayType.LightningDeal ? (
                                  <Trans>Lightning Deal Expired</Trans>
                                ) : null}
                              </>
                            ) : disableTranslation ? (
                              subtitle || discount
                            ) : (
                              <Trans>
                                up to {subtitle || discount} discount
                              </Trans>
                            )}
                          </StyledBrandCardLineClamp>
                          {hasSale && !hasSaleExpired ? (
                            <NoSSR>
                              <Timer
                                endDate={end_date}
                                theme="bannertimer"
                                showLabels={false}
                                onTimerEnd={
                                  display_type ===
                                  PromotionDisplayType.LightningDeal
                                    ? onTimerExpire
                                    : setSaleExpired
                                }
                                timerColor={theme.colors.semantic.red}
                              />
                            </NoSSR>
                          ) : null}
                        </>
                      )}
                    </Text>
                  </Flex>
                  <NoSSR>
                    <Flex
                      sx={{
                        marginLeft: "auto",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ArrowNext role="presentation" />
                    </Flex>
                  </NoSSR>
                </StyledBrandCardInfoText>
              </Flex>
            )}
          </StyledBrandCard>
        </Link>
      )}
      {open && (
        <LoginRegisterModal
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          redirectTo={path}
          trackingData={{
            pageName: "homepage",
            shopType: genderId,
            actionSource: SEGMENT_PROMOTION_TYPE.brandCarousel as string,
            redirectTo: path,
            brandName,
            brandId,
            brandImageUrl: image || image_desktop || image_mobile || "",
            tab,
            level: (brand as HomePageSingleBrand).gate?.level || "non_gated",
          }}
          onRedirect={setRedirectData}
        />
      )}
    </>
  );
};

const BrandCardFallback: FC = () => {
  return (
    <StyledBrandCard>
      <div>
        <Ratio ratio={0.72}>
          <Skeleton />
        </Ratio>

        <StyledBrandCardInfo>
          <StyledBrandCardInfoText>
            <Skeleton height={14} width={130} />
            <div style={{ height: 6 }} />
            <Skeleton height={16} width={130} />
          </StyledBrandCardInfoText>
          <Skeleton height={36} width={100} />
        </StyledBrandCardInfo>
      </div>
    </StyledBrandCard>
  );
};

export { BrandCard, BrandCardFallback };
