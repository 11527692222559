import { FC, ReactNode } from "react";
import styled from "@emotion/styled";

const StyledRatio = styled.div`
  position: relative;
  width: 100%;

  .Ratio__content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

interface Props {
  ratio: number;
  children: ReactNode;
}

const Ratio: FC<Props> = ({ ratio, children, ...restProps }) => {
  return (
    <StyledRatio style={{ paddingTop: `${ratio * 100}%` }} {...restProps}>
      <div className="Ratio__content">{children}</div>
    </StyledRatio>
  );
};

export { Ratio };
