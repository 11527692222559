import { useEffect, useState, Dispatch, SetStateAction } from "react";

const useImageError = (): [
  Dispatch<SetStateAction<HTMLElement | null>>,
  boolean,
  () => void,
  HTMLElement | null
] => {
  const [el, setEl] = useState<HTMLElement | null>(null); // contains image reference
  const [hasImgError, setError] = useState<boolean>(false); // contains error flag

  const handleError = () => {
    setError(true);
  }; // set error
  const retry = () => {
    setError(false);
  }; // set error false to img can re-render

  useEffect(() => {
    // use of error event of the image tag
    el?.addEventListener("error", handleError);

    return () => {
      el?.removeEventListener("error", handleError);
    };
  }, [el]);

  return [
    setEl, // set the image ref
    hasImgError, // error flag
    retry, // a func, which can be used to re-render image
    el, // img ref(for special case which requires ref)
  ];
};

export { useImageError };
