import { Fragment } from "react";
import useTimer, { type TimeLeftKeys } from "src/hooks/useTimer";
import {
  CustomFontSizes,
  CustomSpacings,
  TimerContainer,
} from "./Timer.styles";
import classNames from "classnames";
import { useLingui } from "@lingui/react";

type hideTimerBlockProps = Partial<Record<TimeLeftKeys, boolean>>;

interface TimerProps {
  endDate: string;
  showLabels?: boolean;
  showSeparator?: boolean;
  onTimerEnd?: (hasTimerEnded: boolean) => void;
  theme?: "default" | "promotimer" | "bannertimer";
  customFontSizes?: CustomFontSizes;
  customSpacings?: CustomSpacings;
  oneLetterLabel?: boolean;
  hideTimerBlocks?: hideTimerBlockProps;
  timerColor?: string;
}

function Timer({
  endDate,
  showLabels = true,
  showSeparator = true,
  onTimerEnd,
  theme = "default",
  timerColor,
  customFontSizes,
  customSpacings,
  oneLetterLabel,
  hideTimerBlocks = {
    days: false,
    hrs: false,
    mins: false,
    sec: false,
  },
}: TimerProps): JSX.Element {
  const { timeLeft } = useTimer({
    endTime: endDate,
    onTimerEnd,
  });
  const { i18n } = useLingui();

  const timerKeys = Object.keys(timeLeft).filter(
    (key) => !hideTimerBlocks[key as TimeLeftKeys]
  );

  return (
    <TimerContainer
      className={theme}
      data-testid="timer-component"
      {...{ customFontSizes, customSpacings, timerColor }}
    >
      {timerKeys.map((key, idx) => {
        const label = i18n._(key);

        return (
          <Fragment key={`timer-${key}`}>
            <div
              key={`timer-${key}`}
              className={classNames("timer-block", key, {
                ["timer-block--no-labels"]: !showLabels,
              })}
            >
              <span className="timer-block--numbers">
                {timeLeft[key as TimeLeftKeys]}
              </span>
              {showLabels ? (
                <span className="timer-block--label">
                  {oneLetterLabel ? label.charAt(0) : label}
                </span>
              ) : null}
            </div>

            {showSeparator && idx < timerKeys.length - 1 ? (
              <span
                key={`timer-divider-${idx}`}
                className={classNames("timer-divider", key)}
              >
                :
              </span>
            ) : null}
          </Fragment>
        );
      })}
    </TimerContainer>
  );
}

export { Timer };
